import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import {
  isAndroid,
  isChrome,
  isIOS,
  isSafari,
  isFirefox,
  isBrowser,
  isEdge,
  browserName,
} from 'react-device-detect'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    '& video': {
      height: 'auto',
    },
  },
})

export default function PermissionInstruction() {
  const browser = {
    firefox: require('../../img/firefox.mp4'),
    chrome: require('../../img/chrome.mp4'),
    celular_chrome: require('../../img/celular-chrome.mp4'),
    edge: require('../../img/edge.mp4'),
    celular_safari: require('../../img/celular-safari.mp4'),
    safari: require('../../img/safari.mp4'),
    opera: require('../../img/opera-instruction.gif'),
    samsung_browser: require('../../img/samsung_browser.mp4'),
  }

  let videoInstruction = browser.chrome

  if (isBrowser && isChrome) {
    videoInstruction = browser.chrome
  } else if (isIOS && isSafari) {
    videoInstruction = browser.celular_safari
  } else if (isBrowser && isFirefox) {
    videoInstruction = browser.firefox
  } else if (isBrowser && isEdge) {
    videoInstruction = browser.edge
  } else if (isBrowser && isSafari) {
    videoInstruction = browser.safari
  } else if (isAndroid && isChrome) {
    videoInstruction = browser.celular_chrome
  } else if (browserName === 'Samsung Browser') {
    videoInstruction = browser.samsung_browser
  } else {
    videoInstruction = browser.chrome
  }

  const cs = useStyles()

  return (
    <div className={cs.container}>
      <video
        width="800"
        height="400"
        style={{ maxWidth: '100%' }}
        controls
        autoPlay
        muted
      >
        <source src={videoInstruction} type="video/mp4" />
        Seu navegador não suporta vídeos.
      </video>
    </div>
  )
}
