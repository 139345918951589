import React, { FunctionComponent } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PermissionInstruction from '@/components/PermissionInstruction'

interface PermissionInstructionDialogProps {
  open: boolean
  onClose(): void
  onHelp(): void
}

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '48px',
    lineHeight: '65px',
    margin: 15,
    textAlign: 'center',
    color: theme.palette.primary.dark,
    alignSelf: 'flex-start',
  },
  paperFullWidth: { width: '100%' },
  paper: { margin: 12 },
}))

const PermissionInstructionDialog: FunctionComponent<PermissionInstructionDialogProps> = ({
  open,
  onClose,
  onHelp,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      disableBackdropClick
      style={{ zIndex: 30 }}
      classes={{ paperFullWidth: classes.paperFullWidth, paper: classes.paper }}
    >
      <DialogTitle className={classes.title}>
        Permita acesso à sua câmera e microfone, veja como:
      </DialogTitle>
      <DialogContent>
        <PermissionInstruction />
      </DialogContent>
      <DialogActions style={{ padding: 12, justifyContent: 'space-between' }}>
        <Button onClick={onHelp} variant="outlined" color="primary">
          Ajuda
        </Button>
        <Button onClick={onClose} variant="contained" color="primary">
          Entendi
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PermissionInstructionDialog
