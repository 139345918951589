import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: theme.sidebarWidth,
    padding: '2em',
    overflowY: 'auto',
    background: 'transparent',
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      top: 'unset',
      right: 'unset',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 'auto',
      overflowY: 'initial',
      overflowX: 'auto',
      display: 'flex',
      padding: '8px',
    },
  },
  isRemoteParticipantScreenSharing: {
    position: 'unset',
    height: 'unset',
  },
  scrollContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
}))

export default useStyles
