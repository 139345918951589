import React, {
  FormEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Header from '@/components/Header'
import { PageContainer, Main } from '@/theme/styles'
import { Typography, useMediaQuery, Theme, TextField } from '@material-ui/core'
import useStyles from './styles'
import { useParams } from 'react-router-dom'
import { errorLog } from '@/utils/logger'
import { useDispatch } from 'react-redux'
import BusyButton from '@/components/BusyButton'
import clsx from 'clsx'
import { ReactComponent as FilledStar } from '@/assets/svg/icon_filled_star.svg'
import { ReactComponent as UnfilledStar } from '@/assets/svg/icon_unfilled_star.svg'
import AttendanceService from '@/services/Attendance'
import useVonageContext from '@/hooks/useVonageContext'

const Thankyou: FunctionComponent = () => {
  const cs = useStyles()
  const dispatch = useDispatch()
  const [score, setScore] = useState<null | number>(null)
  const { attendanceToken } = useParams<{ attendanceToken: string }>()
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  )
  const [ratingComplete, setRatingComplete] = useState(false)
  const commentRef = useRef(null)
  const [busy, setBusy] = useState(false)
  const { reset } = useVonageContext()

  const titleVariant = useMemo(() => (isSmallScreen ? 'subtitle1' : 'h3'), [
    isSmallScreen,
  ])

  useEffect(() => {
    dispatch({ type: 'CLEAR_STATE' })
  }, [dispatch])

  useEffect(() => {
    reset()
  }, [reset])

  const submitRating = useCallback(
    async (evt: FormEvent) => {
      evt.preventDefault()

      const comment = commentRef.current?.value || null

      setBusy(true)
      try {
        await AttendanceService.sendRating({
          score,
          comment,
          attendanceToken,
        })

        setRatingComplete(true)
      } catch (err) {
        errorLog(err)
      }
      setBusy(false)
    },
    [score, attendanceToken],
  )

  return (
    <PageContainer>
      <Header />
      <Main className={cs.mainContainer}>
        {ratingComplete ? (
          <Typography
            variant={titleVariant}
            className={cs.successMessage}
            color="textSecondary"
          >
            Avaliação enviada com sucesso!
            <br />
            Você pode fechar essa aba.
          </Typography>
        ) : (
          <>
            <Typography
              variant={titleVariant}
              className={cs.title}
              color="textSecondary"
            >
              Como foi sua experiência de teleatendimento?
            </Typography>
            <form className={cs.form} onSubmit={submitRating}>
              <div className={cs.starsBox}>
                {[1, 2, 3, 4, 5].map(item => (
                  <button
                    type="button"
                    key={item}
                    onClick={() => setScore(item)}
                    className={cs.starBtn}
                  >
                    {score && score >= item ? (
                      <FilledStar className={cs.starIcon} />
                    ) : (
                      <UnfilledStar className={cs.starIcon} />
                    )}
                  </button>
                ))}
              </div>
              <TextField
                placeholder="Queremos ouvir você! Nos diga como foi."
                multiline
                variant="outlined"
                rows={4}
                fullWidth
                inputRef={commentRef}
              />
              <div className={cs.actionsContainer}>
                <BusyButton
                  type="submit"
                  busy={busy}
                  fullWidth={isSmallScreen}
                  className={clsx(cs.submitBtn, { [cs.activeBtn]: score })}
                >
                  Enviar avaliação
                </BusyButton>
              </div>
            </form>
          </>
        )}
      </Main>
    </PageContainer>
  )
}

export default Thankyou
