import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    '& .OTSubscriberContainer': {
      position: 'absolute',
    },
  },

  invertVideo: {
    '& video': {
      transform: 'scale(-1, 1)!important',
      transformOrigin: '50% 50%!important',
    },
  },
})

export default useStyles
