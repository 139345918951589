import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useParams } from 'react-router-dom'

import useVonageContext from '@/hooks/useVonageContext'

import { PageContainer, Main, GrowContainer } from '@/theme/styles'
import MobileTopMenuBar from '@/components/MobileTopMenuBar'
import MenuBar from '@/components/MenuBar'
import { Fade } from '@material-ui/core'
import useStyles from './styles'
import { errorLog, log } from '@/utils/logger'

import { OTPublisher } from 'opentok-react'
import { PublisherEventHandlers } from 'opentok-react/types/opentok'

const ChatPage: FunctionComponent = () => {
  const videoRef = useRef(null)
  const cs = useStyles()
  const {
    connect,
    session,
    videoOn,
    audioOn,
    isSharingScreen,
    toggleScreenShare,
    setPublisher,
  } = useVonageContext()
  const { VonageToken } = useParams<{
    VonageToken?: string
  }>()

  useEffect(() => {
    if (VonageToken) {
      return connect(VonageToken)
    }
  }, [VonageToken, connect])

  const publisherEventHandlers = useMemo<PublisherEventHandlers>(() => ({}), [])

  // TODO: resolver onError de melhor forma. Ex: errorMiddleware
  const onError = useCallback(
    err => {
      errorLog(err)
      if (err.code === 1500 && err.message.includes('screen sharing')) {
        toggleScreenShare()
      }
    },
    [toggleScreenShare],
  )

  return (
    <PageContainer>
      {session && (
        <Fade in timeout={600}>
          <GrowContainer>
            <MobileTopMenuBar />
            <Main>
              <div className={cs.videoContainer}>
                <OTPublisher
                  properties={{
                    style: {
                      audioLevelDisplayMode: 'off',
                      archiveStatusDisplayMode: 'off',
                      nameDisplayMode: 'off',
                      buttonDisplayMode: 'off',
                      videoDisabledDisplayMode: 'off',
                    },
                    publishAudio: audioOn,
                    publishVideo: videoOn,
                    width: '100%',
                    height: '100%',
                    fitMode: 'contain',
                    facingMode: 'user',
                    videoSource: isSharingScreen ? 'screen' : undefined,
                  }}
                  onPublish={log}
                  onError={onError}
                  onInit={() => setPublisher(videoRef.current.getPublisher())}
                  eventHandlers={publisherEventHandlers}
                  ref={videoRef}
                  session={session}
                />
              </div>
            </Main>
            <MenuBar />
          </GrowContainer>
        </Fade>
      )}
    </PageContainer>
  )
}

export default ChatPage
