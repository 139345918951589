import React, { FunctionComponent } from 'react'
import clsx from 'clsx'

// import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator'
import Typography from '@material-ui/core/Typography'

// import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting'
import useIdentityByStream from '@/hooks/useIdentityFromStream'
import useStyles from './styles'
import { Stream } from 'opentok-react/types/opentok'

interface ParticipantInfoProps {
  stream: Stream
  isLocalParticipant?: boolean
  hideParticipant?: boolean
}

const ParticipantInfo: FunctionComponent<ParticipantInfoProps> = ({
  stream,
  children,
  isLocalParticipant,
  hideParticipant,
}) => {
  const participantIdentity = useIdentityByStream(stream)

  const classes = useStyles()

  return (
    <div
      className={clsx(classes.container, {
        [classes.hideParticipant]: hideParticipant,
      })}
    >
      <div className={classes.infoContainer}>
        <div className={classes.infoRowBottom}>
          <span className={classes.identity}>
            {/* <AudioLevelIndicator audioTrack={audioTrack} />  // TODO: para quando implementar audio level */}
            <Typography
              variant="body1"
              className={classes.typeography}
              component="span"
            >
              {participantIdentity}
              {isLocalParticipant && ' (Você)'}
            </Typography>
          </span>
        </div>
      </div>
      <div className={classes.innerContainer}>
        {
          // TODO: verificar necessidade dessa feature
          /* {(!isVideoEnabled || isVideoSwitchedOff) && (
          <div className={classes.avatarContainer}>
            <AvatarIcon />
          </div>
        )} */
        }
        {
          // TODO: verificar necessidade dessa feature
          /* {isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typeography}>
              Reconnecting...
            </Typography>
          </div>
        )} */
        }
        {children}
      </div>
    </div>
  )
}

export default ParticipantInfo
