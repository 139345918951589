import React, { FunctionComponent } from 'react'

import { Route, Switch, Redirect } from 'react-router-dom'
import RequirePatient from './routeGuards/RequirePatient'

import {
  VideoRoom,
  Thankyou,
  Welcome,
  ConsultationGuide,
  WaitingRoom,
  OfficeNotFound,
  UnsupportedClient,
  TestPage,
  ChatPage,
} from '@/pages'
import { isProd } from '@/utils/verifyEnv'

const { REACT_APP_PROFESSIONAL_APP: PROFESSIONAL_APP_URL } = process.env

const Router: FunctionComponent = props => {
  return (
    <Switch {...props}>
      {!isProd() && (
        <Route exact path="/test/:VonageToken" component={TestPage} />
      )}
      {!isProd() && (
        <Route exact path="/chat/:VonageToken" component={ChatPage} />
      )}
      <Route exact path="/unsupported-client" component={UnsupportedClient} />
      <Route exact path="/thankyou/:attendanceToken?" component={Thankyou} />
      <Route exact path="/office-not-found" component={OfficeNotFound} />
      <Route
        exact
        path="/professional-app"
        render={({ location }) => {
          const { search, hash } = location
          window.location.href = `${PROFESSIONAL_APP_URL}${search}${hash}`
          return null
        }}
      />
      <RequirePatient
        exact
        path="/guide/:roomName"
        component={ConsultationGuide}
      />
      <RequirePatient
        exact
        path="/waiting-room/:roomName"
        component={WaitingRoom}
      />
      <Route exact path="/:roomName" component={Welcome} />
      <Route
        exact
        path="/room/:videoToken/:userType?/:attendanceId?"
        component={VideoRoom}
      />

      <Redirect to="/office-not-found" />
    </Switch>
  )
}

export default Router
