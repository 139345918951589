import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  button: {
    margin: `0 ${theme.spacing(1)}px`,
    '&[disabled]': {
      color: '#bbb',
      '& svg *': {
        fill: '#bbb',
      },
    },
  },
}))

export default useStyles
