import React, { FunctionComponent } from 'react'

import { Button } from '@material-ui/core'
import useStyles from './styles'
import clsx from 'clsx'

import useVonageContext from '@/hooks/useVonageContext'

const EndCallButton: FunctionComponent<{ className?: string }> = props => {
  const cs = useStyles()
  const { disconnect } = useVonageContext()

  return (
    <Button onClick={disconnect} className={clsx(cs.button, props.className)}>
      Encerrar
    </Button>
  )
}

export default EndCallButton
