import allTrim from '@/utils/allTrim'
import { useMemo } from 'react'

const prefixes = ['[patient]', '[professional]']

const removeIdentityPrefix = (identity: string): string =>
  prefixes.reduce((acc, prefix) => acc.split(prefix).join(''), identity || '')

export const useIdentityWithoutPrefix = (identity: string): string => {
  const identityWithoutPrefix = useMemo(
    () => allTrim(removeIdentityPrefix(identity)),
    [identity],
  )

  return identityWithoutPrefix
}

export default removeIdentityPrefix
