import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  message: {
    margin: 20,
    textAlign: 'center',
  },
})

export default useStyles
