import { makeStyles, styled } from '@material-ui/core'

export const Header = styled('header')(({ theme }) => ({
  zIndex: 10,
  background: theme.palette.background.default,
  display: 'flex',
  minHeight: 57,
  width: '100%',
  boxShadow: '4px 4px 12px rgba(0,0,0,.05)',
  '& .maxContainer': {
    padding: '0 40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const useStyles = makeStyles({
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '40%',
    height: 'auto',
  },
})

export default useStyles
