import React, { FunctionComponent, useRef, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import { useIdentityWithoutPrefix } from '@/utils/removeIdentityPrefix'
import OT, { Publisher } from '@opentok/client'
import AvatarIcon from '@/assets/icons/AvatarIcon'
import { errorLog } from '@/utils/logger'
import useStyles from './styles'
import useVonageContext from '@/hooks/useVonageContext'

export interface LocalVideoPreviewProps {
  identity: string
  classes?: Record<string, unknown>
  fitMode?: 'cover' | 'contain'
}

const LocalVideoPreview: FunctionComponent<LocalVideoPreviewProps> = ({
  identity,
  classes,
  fitMode,
}) => {
  const cs = useStyles()
  const [publisher, setPublisher] = useState<Publisher>()
  const publisherElemRef = useRef()
  const publisherRef = useRef(publisher)
  const { audioOn, videoOn } = useVonageContext()

  const participantIdentity = useIdentityWithoutPrefix(identity)

  useEffect(() => {
    const publish = async () => {
      try {
        const stream = await OT.getUserMedia()
        // Stop the tracks so that we stop using this camera and microphone
        // If you don't do this then cycleVideo does not work on some Android devices
        stream.getTracks().forEach(track => track.stop())

        const newPublisher = OT.initPublisher(publisherElemRef.current, {
          width: '100%',
          height: '100%',
          fitMode: fitMode || 'contain',
          style: {
            audioLevelDisplayMode: 'off',
            nameDisplayMode: 'off',
            buttonDisplayMode: 'off',
          },
        })

        setPublisher(newPublisher)
      } catch (err) {
        errorLog(err)
      }
    }

    publish()
  }, [fitMode])

  useEffect(() => {
    publisherRef.current = publisher
  }, [publisher])

  useEffect(() => {
    publisherRef.current?.publishAudio(audioOn)
  }, [audioOn])

  useEffect(() => {
    publisherRef.current?.publishVideo(videoOn)
  }, [videoOn])

  return (
    <div className={clsx(cs.container, classes?.container)}>
      <div className={cs.innerContainer}>
        {publisher ? null : (
          <div className={cs.avatarContainer}>
            <AvatarIcon />
          </div>
        )}
        <div ref={publisherElemRef} style={{ position: 'absolute' }} />
      </div>

      <div className={cs.identityContainer}>
        {!participantIdentity ? null : (
          <span className={cs.identity}>
            {/* <LocalAudioLevelIndicator /> */}
            <Typography variant="body1" color="inherit" component="span">
              {participantIdentity}
            </Typography>
          </span>
        )}
      </div>
    </div>
  )
}

export default LocalVideoPreview
