import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react'

import MainParticipantInfo from '@/components/MainParticipantInfo'
import { OTPublisher, OTSubscriber } from 'opentok-react'
import useStyles from './styles'
import { publisherDefaults, subscriberDefaults } from './OTDefaults'
import { errorLog, log } from '@/utils/logger'

import { PublisherEventHandlers } from 'opentok-react/types/opentok'

import useVonageContext from '@/hooks/useVonageContext'
import useRemoteScreenSharingStream from '@/hooks/useRemoteScreenSharingStream'
import useRemoteCameraStreams from '@/hooks/useRemoteCameraStreams'

const MainParticipant: FunctionComponent = () => {
  const cs = useStyles()
  const {
    session,
    videoOn,
    audioOn,
    isSharingScreen,
    setPublisher,
    toggleScreenShare,
    localMainStream,
    setLocalMainStream,
  } = useVonageContext()
  const cameraStreams = useRemoteCameraStreams()
  const screenShareStream = useRemoteScreenSharingStream()
  const videoRef = useRef(null)

  const publisherEventHandlers = useMemo<PublisherEventHandlers>(
    () => ({
      streamCreated: event => {
        log('streamCreated>', event)
        setLocalMainStream(event.stream)
      },
      streamDestroyed: () => setLocalMainStream(null),
    }),
    [setLocalMainStream],
  )

  const currMainStream = useMemo(
    () =>
      !isSharingScreen
        ? screenShareStream || cameraStreams[0] || localMainStream
        : localMainStream,
    [isSharingScreen, screenShareStream, cameraStreams, localMainStream],
  )

  const mainIsLocal = useMemo(
    () => isSharingScreen || !(cameraStreams.length || screenShareStream),
    [isSharingScreen, cameraStreams, screenShareStream],
  )

  // TODO: resolver onError de melhor forma. Ex: errorMiddleware
  const onError = useCallback(
    err => {
      errorLog(err)
      if (err.code === 1500 && err.message.includes('screen sharing')) {
        toggleScreenShare()
      }
    },
    [toggleScreenShare],
  )

  return (
    <MainParticipantInfo
      stream={currMainStream}
      isLocalParticipant={mainIsLocal}
    >
      <div
        className={cs.videoContainer}
        style={
          cameraStreams.length || isSharingScreen
            ? { display: 'none' }
            : undefined
        }
      >
        <OTPublisher
          properties={{
            ...publisherDefaults,
            publishAudio: audioOn,
            publishVideo: videoOn,
            facingMode: 'user',
          }}
          onPublish={log}
          onError={onError}
          onInit={() => setPublisher(videoRef.current.getPublisher())}
          eventHandlers={publisherEventHandlers}
          ref={videoRef}
          session={session}
        />
      </div>
      {isSharingScreen ? (
        <div className={cs.videoContainer}>
          <OTPublisher
            properties={{
              ...publisherDefaults,
              publishVideo: true,
              videoSource: 'screen',
            }}
            eventHandlers={{}}
            onPublish={log}
            onError={onError}
            session={session}
          />
        </div>
      ) : screenShareStream || cameraStreams.length ? (
        <div className={cs.videoContainer}>
          <OTSubscriber
            key={(screenShareStream || cameraStreams[0]).streamId}
            properties={subscriberDefaults}
            // TODO: Resolver prioridade da stream a ser mostrada como main (atualmente está fixa sempre como a primeira stream)
            stream={screenShareStream || cameraStreams[0]}
            session={session}
          />
        </div>
      ) : null}
    </MainParticipantInfo>
  )
}

export default MainParticipant
