import { useContext } from 'react'
import { VonageContext, IVonageContext } from '@/components/VonageProvider'

export default function useVonageContext(): IVonageContext {
  const context = useContext(VonageContext)
  if (!context) {
    throw new Error('useVonageContext must be used within a VonageProvider')
  }
  return context
}
