import React, { FunctionComponent } from 'react'
import ReactDOM from 'react-dom'

import { Provider as StoreProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Store from '@/store'

import useGTM from '@elgorditosalsero/react-gtm-hook'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import AppStateProvider from '@/state'
import App from '@/App'
import theme from '@/theme'
import '@/types'
import { BrowserRouter } from 'react-router-dom'

const { store, persistor } = Store()

const Index: FunctionComponent = () => {
  const { UseGTMHookProvider } = useGTM()

  return (
    <UseGTMHookProvider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <StoreProvider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <AppStateProvider>
                <App />
              </AppStateProvider>
            </BrowserRouter>
          </PersistGate>
        </StoreProvider>
      </MuiThemeProvider>
    </UseGTMHookProvider>
  )
}

ReactDOM.render(<Index />, document.getElementById('root'))
