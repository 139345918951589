import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ProfessionalName } from '@/store/ducks/consultationRoom/getters'

import { useHistory, useParams } from 'react-router-dom'

import Header from '@/components/Header'
import BusyButton from '@/components/BusyButton'
import LoadingContainer from '@/components/LoadingContainer'
import ConsultationRoom from '@/services/ConsultationRoom'
import { Typography, TextField, Checkbox } from '@material-ui/core'
import { ReactComponent as IconStetos } from '@/assets/svg/icon_stetos.svg'

import { PageContainer, Main } from '@/theme/styles'
import useStyles, { Content, Form } from './styles'

import { ApplicationState } from '@/store'
import {
  setConsultationRoom,
  setLoading,
} from '@/store/ducks/consultationRoom/actions'
import { setUser } from '@/store/ducks/auth/actions'
import { setAttendance } from '@/store/ducks/attendance/actions'

import Attendance from '@/services/Attendance'

import { errorLog } from '@/utils/logger'
import camelcaseKeys from 'camelcase-keys'
import wait from '@/utils/wait'
import { useAppState } from '@/state'

import jwtDecode from 'jwt-decode'
const { REACT_APP_TERM_URL: TERM_URL } = process.env

const Welcome: FunctionComponent = () => {
  const { setError } = useAppState()
  const cs = useStyles()
  const history = useHistory()
  const { roomName }: { roomName: string } = useParams()
  const inputNameRef = useRef(null)
  const termCheckRef = useRef(null)
  const [submitBusy, setSubmitBusy] = useState(false)
  const dispatch = useDispatch()
  const {
    room: { id: roomId },
    loading,
  } = useSelector((state: ApplicationState) => state.consultationRoom)
  const professionalName = useSelector(ProfessionalName)

  const handleSubmit = useCallback(
    async evt => {
      setSubmitBusy(true)
      evt.preventDefault()
      try {
        if (!inputNameRef.current.value || !termCheckRef.current.checked)
          throw new TypeError('Inputs must be filled')

        const objToSend = {
          roomId,
          patientName: inputNameRef.current.value,
        }

        const response = await Attendance.create(objToSend)

        const { patientId, attendanceId } = camelcaseKeys(
          jwtDecode(response.data.token),
        )

        dispatch(
          setUser({
            patientId,
            name: objToSend.patientName,
            userToken: response.data.token,
          }),
        )
        dispatch(setAttendance({ id: attendanceId }))
        history.push(`/guide/${roomName}`)
      } catch (err) {
        setSubmitBusy(false)
        errorLog(err)
      }
    },
    [history, roomName, roomId, dispatch],
  )

  useEffect(() => {
    const fetchInfos = async () => {
      dispatch(setLoading(true))
      try {
        const response = await ConsultationRoom.getByName(roomName)
        dispatch(setConsultationRoom(camelcaseKeys(response.data)))
        await wait(600)
      } catch (err) {
        errorLog(err)
        if (err?.response?.status === 400)
          return history.push('/office-not-found')

        setError(
          new Error(
            'Houve um problema ao acessar o consultório. Por favor, tente mais tarde.',
          ),
        )
      }
      dispatch(setLoading(false))
    }

    fetchInfos()
  }, [dispatch, roomName, history, setError])

  return (
    <PageContainer>
      <Header />
      {loading ? (
        <LoadingContainer />
      ) : (
        <Main>
          <div className={cs.boxProfessionalWrapper}>
            <div className="maxContainer">
              <IconStetos className={cs.iconStetos} />
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={cs.textOverflow}
              >
                Dr(a) {professionalName}
              </Typography>
            </div>
          </div>
          <Content>
            <Form onSubmit={handleSubmit}>
              <div className={cs.contentTop}>
                <Typography
                  variant="h2"
                  color="textSecondary"
                  className={cs.wellcome}
                >
                  Bem-vinda(o)!
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Está na hora da sua consulta? <br />
                  Faça seu check-in!
                </Typography>
              </div>
              <TextField
                required
                fullWidth
                label="Nome"
                placeholder="Digite aqui seu nome"
                inputRef={inputNameRef}
              />
              <div className={cs.contentBottom}>
                <div className={cs.termBox}>
                  <Checkbox
                    inputRef={termCheckRef}
                    required
                    size="small"
                    color="primary"
                    className={cs.termCheck}
                  />
                  <Typography variant="body2">
                    Concordo com os{' '}
                    <a
                      href={TERM_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={cs.link}
                    >
                      termos de uso.
                    </a>
                  </Typography>
                </div>
                <BusyButton
                  type="submit"
                  busy={submitBusy}
                  className={cs.submitButton}
                  fullWidth
                >
                  Fazer check-in
                </BusyButton>
              </div>
            </Form>
          </Content>
        </Main>
      )}
    </PageContainer>
  )
}

export default Welcome
