import moment from 'moment'

import { ApplicationState } from '@/store'

interface PatientEntryReturn {
  name: string
  patient_id: number
  room_name: string
  room_id: number
  appointment_id: number
  start_waiting_date: string
}

export const PatientEntry = (state: ApplicationState): PatientEntryReturn => {
  const {
    auth: { user },
    attendance: { attendance },
    consultationRoom: { room },
  } = state

  return {
    name: user.name,
    patient_id: user.patientId,
    room_name: room.roomName,
    room_id: room.id,
    appointment_id: attendance.id,
    start_waiting_date: moment.utc().toString(),
  }
}
