import { errorLog } from '@/utils/logger'
import { useMemo } from 'react'
import allTrim from '@/utils/allTrim'
import removeIdentityPrefix from '@/utils/removeIdentityPrefix'
import { Stream } from 'opentok-react/types/opentok'

const useIdentityByStream = (stream: Stream): string => {
  const identity = useMemo(() => {
    if (!stream) return ''

    try {
      const { name } = JSON.parse(stream.connection.data)
      return allTrim(removeIdentityPrefix(name))
    } catch (err) {
      errorLog(err)
      return ''
    }
  }, [stream])

  return identity
}

export default useIdentityByStream
