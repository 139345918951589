import { Reducer } from 'redux'
import { ConsultationRoomState } from './types'
import Handlers from './handlers'

const initialState: ConsultationRoomState = {
  room: {
    id: null,
    roomName: null,
    professionalFullname: null,
  },
  loading: false,
}

const reducer: Reducer<ConsultationRoomState> = (
  state = initialState,
  { type, payload },
) => {
  const currHandler = Handlers[type]

  if (!currHandler) return state

  return currHandler(state, payload)
}

export default reducer
