import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react'

import Button from '@material-ui/core/Button'
import VideoOffIcon from '@/assets/icons/VideoOffIcon'
import VideoOnIcon from '@/assets/icons/VideoOnIcon'

import { useVideoInputDevices } from '@/hooks/useDevices'
import useVonageContext from '@/hooks/useVonageContext'

const ToggleVideoButton: FunctionComponent<{
  disabled?: boolean
  className?: string
}> = props => {
  const { videoOn, toggleVideo } = useVonageContext()
  const lastClickTimeRef = useRef(0)
  const videoInputDevices = useVideoInputDevices()

  const hasVideoDevices = useMemo(() => !!videoInputDevices?.length, [
    videoInputDevices,
  ])

  const debouncedToggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()
      toggleVideo()
    }
  }, [toggleVideo])

  return (
    <Button
      className={props.className}
      onClick={debouncedToggleVideo}
      disabled={!hasVideoDevices || props.disabled}
      startIcon={videoOn ? <VideoOnIcon /> : <VideoOffIcon />}
    >
      {!hasVideoDevices ? 'Sem Vídeo' : videoOn ? 'Desabilitar' : 'Habilitar'}
    </Button>
  )
}

export default ToggleVideoButton
