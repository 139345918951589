import { useState, useEffect, useMemo } from 'react'
import OT from '@opentok/client'

const useDevices = (): OT.Device[] => {
  const [devices, setDevices] = useState<OT.Device[]>(null)

  useEffect(() => {
    const getDevices = () =>
      OT.getDevices((error, devices) =>
        error
          ? console.log('error when get devices', error)
          : setDevices(devices),
      )
    navigator.mediaDevices.addEventListener('devicechange', getDevices)
    getDevices()

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices)
    }
  }, [])

  return devices
}

export const useAudioInputDevices = (): OT.Device[] | null => {
  const devices = useDevices()

  const audioDevices = useMemo(
    () => devices?.filter(device => device.kind === 'audioInput') || null,
    [devices],
  )

  return audioDevices
}

export const useVideoInputDevices = (): OT.Device[] | null => {
  const devices = useDevices()

  const videoDevices = useMemo(
    () => devices?.filter(device => device.kind === 'videoInput') || null,
    [devices],
  )

  return videoDevices
}

export const audioInputDevices = (devices: OT.Device[]): OT.Device[] =>
  devices?.filter(device => device.kind === 'audioInput')

export default useDevices
