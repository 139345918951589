import { createMuiTheme } from '@material-ui/core'
declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    dark?: string
  }
  interface PaletteColor {
    medium?: string
  }
  interface SimplePaletteColorOptions {
    medium?: string
  }
  interface Palette {
    background: TypeBackground
    gray: PaletteColor
  }
  interface PaletteOptions {
    background?: Partial<TypeBackground>
    gray: PaletteOptions['primary']
  }
}
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    sidebarWidth: number
    sidebarMobileHeight: number
    footerHeight: number
    mobileTopBarHeight: number
    mobileFooterHeight: number
  }
  interface ThemeOptions {
    sidebarWidth?: number
    sidebarMobileHeight?: number
    footerHeight: number
    mobileTopBarHeight: number
    mobileFooterHeight: number
  }
}

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#8296E5',
      main: '#2E50D4',
      medium: '#2540AA',
      dark: '#1C307F',
    },
    secondary: {
      light: '#FFB3A3',
      main: '#FF6746',
    },
    gray: {
      light: '#F4F2F1',
      main: '#DAD2D0',
      medium: '#999392',
      dark: '#575453',
    },
    background: {
      default: '#FFFFFB',
      dark: '#FBFBF9',
    },
    error: {
      light: '#ed949d',
      main: '#EF4056',
      medium: '#e6123d',
      dark: '#b80023',
    },
  },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: 48,
      lineHeight: '66px',
    },
    h2: {
      margin: 0,
      fontWeight: 700,
      fontSize: 28,
      lineHeight: '38px',
    },
    h3: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32px',
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '24px',
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '20px',
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
    },
    button: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: 1,
      textTransform: 'initial',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '8px 16px',
        borderRadius: 8,
      },
      outlined: {
        padding: '7px 15px',
        borderWidth: 2,
      },
      outlinedPrimary: {
        '&,&:hover': {
          borderWidth: 2,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
      },
    },
  },
  // sidebarWidth: 260,
  // sidebarMobileHeight: 120,
  footerHeight: 72,
  mobileFooterHeight: 56,
  sidebarWidth: 355,
  sidebarMobileHeight: 90,
  mobileTopBarHeight: 52,
})

const { gray, primary } = theme.palette

theme.palette.text = {
  primary: gray.dark,
  secondary: primary.dark,
  disabled: gray.light,
  hint: gray.medium,
}

theme.overrides.MuiFormLabel = {
  root: {
    color: gray.dark,
  },
}

export default theme
