import React, { FunctionComponent } from 'react'
import Box from '@material-ui/core/Box'
import Error from '@material-ui/icons/Error'
import { makeStyles, styled } from '@material-ui/core/styles'

const useStyles = makeStyles({
  boxcontainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
})

const IconError = styled(Error)({
  color: '#ff0000',
  marginRight: 5,
  paddingTop: 8,
})

const Text = styled('p')({
  fontFamily: 'Nunito, sans-serif',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '20px',
  marginLeft: 20,
})

const H1 = styled('h1')(({ theme }) => ({
  fontFamily: 'Nunito, sans-serif',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontSize: '48px',
  lineHeight: '65px',
  margin: 15,
  textAlign: 'center',
  color: theme.palette.primary.dark,
  alignSelf: 'flex-start',
}))

const UnsupportedBrowser: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <Box className={classes.boxcontainer}>
      <Box>
        <IconError style={{ fontSize: 60 }}></IconError>
        <H1>O seu navegador não é suportado.</H1>
        <Text>
          Se você está utilizando o computador utilize o Google Chrome versão 75
          ou superior, o Mozilla Firefox versão 72 ou superior ou o Safari
          versão 13 ou superior.
        </Text>
        <Text>
          Agora, se você está utilizando no celular, você pode utilizar o Google
          Chrome no Android ou o Safari no iOS.
        </Text>
      </Box>
    </Box>
  )
}

export default UnsupportedBrowser
