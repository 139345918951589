import { makeStyles, styled } from '@material-ui/core'

export const useGlobalStyles = makeStyles({
  '@global': {
    '.maxContainer': {
      width: '100%',
      maxWidth: '1400px',
      margin: '0 auto',
    },
    // Zendesk balloon
    'iframe#launcher': {
      left: 'unset!important',
      right: '0!important',
    },
    'iframe#webWidget': {
      left: 'unset!important',
      right: '0!important',
    },
  },
})

export const PageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
})

export { PageContainer as GrowContainer }

export const Main = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
})
