import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles({
  dialogTitle: {
    color: '#FF9900',
  },
  dialogContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconDialogTitle: {
    marginRight: 10,
    color: '#FF9900',
  },
})

export default function PermissionDialogTitle() {
  const classes = useStyles()
  return (
    <DialogTitle className={classes.dialogTitle}>
      <div className={classes.dialogContainer}>
        <WarningRoundedIcon
          className={classes.iconDialogTitle}
        ></WarningRoundedIcon>
        <span>Acessos negados</span>
      </div>
    </DialogTitle>
  )
}
