import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Main, PageContainer } from '@/theme/styles'
import Header from '@/components/Header'
import PreviewAndConnect from './PreviewAndConnect'
import PermissionHandler, {
  PermissionHandles,
} from '@/components/PermissionHandler'

const DisconnectedHall: FunctionComponent = () => {
  const [mediaError, setMediaError] = useState<Error>(null)
  const permissionRef = useRef<PermissionHandles>(null)
  const [permissionsGranted, setPermissionsGranted] = useState(false)
  const mediaStreamsRef = useRef<MediaStream>(null)

  useEffect(() => {
    const getPermissions = async () => {
      try {
        mediaStreamsRef.current = await OT.getUserMedia()
        // Stop the tracks so that we stop using this camera and microphone
        // If you don't do this then cycleVideo does not work on some Android devices
        mediaStreamsRef.current.getTracks().forEach(track => track.stop())
        setMediaError(null)
        setPermissionsGranted(true)
      } catch (err) {
        setPermissionsGranted(false)
        setMediaError(prevState =>
          prevState?.name !== err?.name ? err : prevState,
        )
      }
    }

    getPermissions()
    return () =>
      mediaStreamsRef.current?.getTracks().forEach(track => track.stop())
  }, [])

  useEffect(() => {
    const permissionAlert = permissionRef.current

    if (mediaError === null) return

    console.dir(mediaError)
    if (mediaError?.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
      permissionAlert.openAlert()
    }

    return permissionAlert.resetAlert
  }, [mediaError])

  return (
    <PageContainer style={{ width: '100%' }}>
      <Header />
      <Main>
        <div
          style={{
            width: '100%',
            maxWidth: 400,
            maxHeight: 400,
            padding: '0 20px',
          }}
        >
          <PreviewAndConnect
            mediaError={mediaError}
            permissionsGranted={permissionsGranted}
          />
        </div>
      </Main>
      <PermissionHandler ref={permissionRef} />
    </PageContainer>
  )
}

export default DisconnectedHall
