import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  videoContainer: {
    width: '100%',
    display: 'flex',

    '& >div': {
      flex: 1,
    },
  },
})

export default useStyles
