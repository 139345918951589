import React, { FunctionComponent, useState, useMemo, useCallback } from 'react'

import { Button } from '@material-ui/core'
import Header from '@/components/Header'
import { PageContainer, Main } from '@/theme/styles'
import CardInstruction from '@/components/CardInstruction'
// import PermissionHandler from '@/components/PermissionHandler'

import useStyles from './styles'
import { useHistory, useParams } from 'react-router-dom'
import isUnsuportedClient from '@/utils/isUnsuportedClient'

const ConsultationGuide: FunctionComponent = () => {
  const [instructionCount, setInstructionCount] = useState(1)
  const history = useHistory()
  const params = useParams<{ roomName: string }>()

  const infoCards = useMemo(
    () => [
      {
        title: 'Permita acessos',
        description:
          'Quando solicitado, permita acesso à sua câmera e microfone. Isto é essencial para a consulta.',
        image: require('@/assets/images/step-1.png'),
      },
      {
        title: 'Otimize a comunicação',
        description:
          'Esteja em um lugar tranquilo e silencioso, e procure usar fones de ouvido.',
        image: require('@/assets/images/step-2.png'),
      },
      {
        title: 'Respeite o profissional',
        description:
          'Esteja devidamente vestido e seja gentil com quem lhe atende.',
        image: require('@/assets/images/step-3.png'),
      },
      {
        title: 'Garanta uma boa conexão',
        description: 'Preferencialmente, esteja conectado à uma rede Wi-fi.',
        image: require('@/assets/images/step-4.png'),
      },
    ],
    [],
  )

  const startVideoFunction = useCallback(() => {
    history.push(`/waiting-room/${params.roomName}`)
  }, [history, params.roomName])

  const nextButtonLabel = useMemo(
    () =>
      instructionCount < infoCards.length ? 'Próximo' : 'Ir para consulta',
    [instructionCount, infoCards.length],
  )

  const nextButtonAction = useCallback(
    () =>
      instructionCount === infoCards.length
        ? startVideoFunction()
        : nextInstruction(instructionCount + 1),
    [instructionCount, infoCards.length, startVideoFunction],
  )

  const cs = useStyles()

  function nextInstruction(instruction: number) {
    setInstructionCount(instruction)
  }

  if (isUnsuportedClient()) {
    history.replace({ pathname: '/unsupported-client' })
  }

  return (
    <PageContainer>
      <Header />
      <Main>
        <div className={cs.container}>
          <span className={cs.title}>Guia de consulta</span>
          <CardInstruction
            info={infoCards[instructionCount - 1]}
            counter_dots={infoCards.length}
            current_dot={instructionCount}
          />

          <div className={cs.buttonContainer}>
            {instructionCount > 1 ? (
              <Button
                color="primary"
                onClick={() => nextInstruction(instructionCount - 1)}
              >
                Anterior
              </Button>
            ) : (
              <div />
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={nextButtonAction}
            >
              {nextButtonLabel}
            </Button>
          </div>
        </div>
      </Main>
      {/* <PermissionHandler /> // TEMP */}
    </PageContainer>
  )
}

export default ConsultationGuide
