import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded'
import BlockedIcon from './BlockedIcon'

const useStyles = makeStyles({
  buttonIcon: {
    dropShadow: '0px 4px 10px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#2E50D4',
    borderRadius: '50%',
    width: 58,
    height: 58,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconColor: {
    color: 'white',
  },
  container: {
    display: 'flex',
    margin: 20,
  },
})

export default function PermissionVideoBlockedIcon() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.buttonIcon}>
        <VideocamRoundedIcon
          className={classes.iconColor}
        ></VideocamRoundedIcon>
      </div>
      <BlockedIcon />
    </div>
  )
}
