import { ApplicationState } from '@/store'
import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'

const RequirePatient: FunctionComponent<RouteProps> = ({
  children,
  component: Component,
  ...rest
}) => {
  const { patientId } = useSelector(
    (state: ApplicationState) => state.auth.user,
  )

  return (
    <Route
      {...rest}
      render={props => {
        const { roomName } = props.match.params

        return patientId ? (
          Component ? (
            <Component {...props} />
          ) : (
            { children }
          )
        ) : (
          <Redirect
            to={{
              pathname: roomName ? `/${roomName}` : '/office-not-found',
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

export default RequirePatient
