import { Base64 } from 'js-base64'
import queryToObj from '@/utils/queryToObj'

export interface IConnectionData {
  name: string
  role: 'professional' | 'patient'
  session_role: 'MODERATOR' | 'PUBLISHER' | 'SUBSCRIBER'
  token?: string
}

export interface VonageTokenData {
  connection_data?: IConnectionData | Record<string, unknown> | string
  create_time: string
  expire_time: string
  initial_layout_class_list: string
  nonce: string
  partner_id: string
  role: string
  session_id: string
  sig: string
}

const decodeVonageToken = (token: string): VonageTokenData => {
  let decoded = queryToObj<VonageTokenData>(
    Base64.decode(token.replace('T1==', '')).replace(':', '&'),
  )
  if (decoded.connection_data) {
    window.teste = decoded.connection_data
    decoded = {
      ...decoded,
      connection_data: JSON.parse(decoded.connection_data as string),
    }
  }

  return decoded
}

export default decodeVonageToken
