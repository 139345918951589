import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react'

import decodeVonage from '@/utils/decodeVonageToken'
import { log } from '@/utils/logger'
import isMobile from '@/utils/isMobile'

import { Stream, Session, Publisher } from 'opentok-react/types/opentok'
import { createSession, SessionHelper } from 'opentok-react'
import '@opentok/client'

const { REACT_APP_VONAGE_API_KEY: VONAGE_API_KEY } = process.env

export interface IDisconnect {
  (): void
}

export interface IConnect {
  (token: string): IDisconnect
}

export interface IVonageContext {
  session: Session
  streams: Stream[]
  connect: IConnect
  disconnect: IDisconnect
  audioOn: boolean
  videoOn: boolean
  isSharingScreen: boolean
  toggleAudio(): void
  toggleVideo(): void
  toggleScreenShare(): void
  publisher: Publisher
  setPublisher: React.Dispatch<React.SetStateAction<Publisher>>
  localMainStream: Stream
  setLocalMainStream: React.Dispatch<React.SetStateAction<Stream>>
  reset(): void
}

export const VonageContext = createContext<IVonageContext>(null)

const VonageProvider: FunctionComponent = ({ children }) => {
  const [audioOn, setAudioOn] = useState(true)
  const [videoOn, setVideoOn] = useState(true)
  const [isSharingScreen, setSharingScreen] = useState(false)
  const [publisher, setPublisher] = useState<Publisher>(null)

  const [sessionHelper, setSessionHelper] = useState<SessionHelper>(null)
  const session = useMemo(() => sessionHelper?.session || null, [sessionHelper])
  const [streams, setStreams] = useState([])
  const [localMainStream, setLocalMainStream] = useState<Stream>(null)

  const connect = useCallback((token: string) => {
    const sessionId = decodeVonage(token).session_id

    // TODO: aceitar options por parametro
    const helper = createSession({
      apiKey: VONAGE_API_KEY,
      sessionId,
      token,
      onStreamsUpdated: streams => {
        setStreams([...streams])
        log('streams>', streams)
      },
    })

    setSessionHelper(helper)

    helper.session.once('sessionDisconnected', () => {
      window.removeEventListener('beforeunload', helper.disconnect)

      if (isMobile) {
        window.removeEventListener('pagehide', helper.disconnect)
      }
    })

    window.addEventListener('beforeunload', helper.disconnect)

    if (isMobile) {
      window.addEventListener('pagehide', helper.disconnect)
    }

    return helper.disconnect
  }, [])

  const reset = useCallback(() => {
    setSharingScreen(false)
    setPublisher(null)
    setSessionHelper(null)
    setStreams([])
    setLocalMainStream(null)
  }, [])

  const disconnect = useCallback(() => sessionHelper?.disconnect(), [
    sessionHelper,
  ])

  const toggleAudio = useCallback(() => setAudioOn(prevState => !prevState), [])
  const toggleVideo = useCallback(() => setVideoOn(prevState => !prevState), [])

  const toggleScreenShare = useCallback(
    () => setSharingScreen(prevState => !prevState),
    [],
  )

  return (
    <VonageContext.Provider
      value={{
        session,
        streams,
        connect,
        disconnect,
        audioOn,
        videoOn,
        isSharingScreen,
        toggleAudio,
        toggleVideo,
        toggleScreenShare,
        publisher,
        setPublisher,
        localMainStream,
        setLocalMainStream,
        reset,
      }}
    >
      {children}
    </VonageContext.Provider>
  )
}

export default VonageProvider
