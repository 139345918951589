import React, { FunctionComponent, useMemo } from 'react'

import Button from '@material-ui/core/Button'
import ScreenShareIcon from '@/assets/icons/ScreenShareIcon'
import Tooltip from '@material-ui/core/Tooltip'
import useStyles from './styles'

// import useScreenShareParticipant from '@/hooks/useScreenShareParticipant/useScreenShareParticipant'
import useVonageContext from '@/hooks/useVonageContext'
import useRemoteScreenSharingStream from '@/hooks/useRemoteScreenSharingStream'

export const SCREEN_SHARE_TEXT = 'Apresentar tela'
export const STOP_SCREEN_SHARE_TEXT = 'Parar apresentação'
export const SHARE_IN_PROGRESS_TEXT =
  'Não é possível apresentar a tela enquanto outro está apresentando'
export const SHARE_NOT_SUPPORTED_TEXT =
  'Seu navegador não suporta compartilhamento de tela'

const ToggleScreenShareButton: FunctionComponent<{
  disabled?: boolean
}> = props => {
  const { toggleScreenShare } = useVonageContext()
  const remoteScreenSharing = useRemoteScreenSharingStream()
  const classes = useStyles()
  const disableScreenShareButton = useMemo(() => Boolean(remoteScreenSharing), [
    remoteScreenSharing,
  ])
  const isScreenShareSupported = useMemo(
    () => !!navigator?.mediaDevices?.getDisplayMedia,
    [],
  )

  const isDisabled = useMemo(
    () => props.disabled || disableScreenShareButton || !isScreenShareSupported,
    [props.disabled, isScreenShareSupported, disableScreenShareButton],
  )

  const tooltipMessage = useMemo(
    () =>
      !isScreenShareSupported
        ? SHARE_NOT_SUPPORTED_TEXT
        : disableScreenShareButton
        ? SHARE_IN_PROGRESS_TEXT
        : '',
    [isScreenShareSupported, disableScreenShareButton],
  )

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <span>
        {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
        <Button
          className={classes.button}
          onClick={() => toggleScreenShare()}
          disabled={isDisabled}
          startIcon={<ScreenShareIcon />}
          data-cy-share-screen
        >
          {SCREEN_SHARE_TEXT}
        </Button>
      </span>
    </Tooltip>
  )
}

export default ToggleScreenShareButton
