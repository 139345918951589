import React, { FunctionComponent, useMemo } from 'react'

import Button from '@material-ui/core/Button'
import MicIcon from '@/assets/icons/MicIcon'
import MicOffIcon from '@/assets/icons/MicOffIcon'
import { useAudioInputDevices } from '@/hooks/useDevices'

import useVonageContext from '@/hooks/useVonageContext'

const ToggleAudioButton: FunctionComponent<{
  disabled?: boolean
  className?: string
}> = props => {
  const { audioOn, toggleAudio } = useVonageContext()
  const audioInputDevices = useAudioInputDevices()

  const hasAudioDevices = useMemo(() => Boolean(audioInputDevices?.length), [
    audioInputDevices,
  ])

  return (
    <Button
      className={props.className}
      onClick={toggleAudio}
      disabled={!hasAudioDevices || props.disabled}
      startIcon={hasAudioDevices && audioOn ? <MicIcon /> : <MicOffIcon />}
    >
      {!hasAudioDevices ? 'Sem Audio' : audioOn ? 'Desabilitar' : 'Habilitar'}
    </Button>
  )
}

export default ToggleAudioButton
