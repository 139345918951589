import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useSelector } from 'react-redux'
import { ProfessionalName } from '@/store/ducks/consultationRoom/getters'
import { PatientEntry } from '@/store/rootGetters'
import AttendanceService from '@/services/Attendance'

import Header from '@/components/Header'
import { Typography } from '@material-ui/core'
import ErrorDialog from '@/components/ErrorDialog/ErrorDialog'
import PermissionHandler, {
  PermissionHandles,
} from '@/components/PermissionHandler'
import { ReactComponent as HourGlassIcon } from '@/assets/svg/icon-hourglass.svg'

import useStyles, { BoxContent } from './styles'
import { PageContainer, Main } from '@/theme/styles'

import { useHistory } from 'react-router-dom'
import { errorLog } from '@/utils/logger'
import io from 'socket.io-client'
import moment from 'moment'

import LocalVideoPreview from '@/components/LocalVideoPreview'
import OT from '@opentok/client'

import { UserToken } from '@/store/ducks/auth/getters'

const WaitingRoom: FunctionComponent = () => {
  const cs = useStyles()
  const professionalName = useSelector(ProfessionalName)
  const userToken = useSelector(UserToken)
  const history = useHistory()
  const [permissionsGranted, setPermissionsGranted] = useState(false)
  const socketRef = useRef(null)
  const patientEntry = useSelector(PatientEntry)
  const patientEntryRef = useRef(patientEntry)
  const permissionRef = useRef<PermissionHandles>(null)
  const [error, setError] = useState(null)
  const [mediaError, setMediaError] = useState<Error>(null)

  const clearError = useCallback(() => setError(null), [])

  useEffect(() => {
    patientEntryRef.current = patientEntry
  }, [patientEntry])

  const updateEndReception = useCallback(async () => {
    try {
      const nowUtc = moment.utc().toString()
      await AttendanceService.update({
        userToken,
        end_patient_reception: nowUtc,
      })
    } catch (err) {
      errorLog(err)
    }
  }, [userToken])

  useEffect(() => {
    updateEndReception()
  }, [updateEndReception])

  const connectQueue = useCallback(() => {
    const socket = socketRef.current
    const patientEntry = patientEntryRef.current
    socket.emit('/room/patient', patientEntry)

    interface callData {
      patient_id: number
      patient_token: string
      room_id: string
    }

    socket.once(
      `/call/${patientEntry.patient_id}/room/${patientEntry.room_id}/appointment/${patientEntry.appointment_id}`,
      ({ patient_token }: callData) => {
        history.push(
          `/room/${patient_token}/patient/${patientEntry.appointment_id}`,
        )
      },
    )
  }, [history])

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const stream = await OT.getUserMedia()
        // Stop the tracks so that we stop using this camera and microphone
        // If you don't do this then cycleVideo does not work on some Android devices
        stream.getTracks().forEach(track => track.stop())
        setMediaError(null)
        setPermissionsGranted(true)
      } catch (err) {
        setPermissionsGranted(false)
        setMediaError(prevState =>
          prevState?.name !== err?.name ? err : prevState,
        )
      }
    }

    getPermissions()
  }, [])

  useEffect(() => {
    const permissionAlert = permissionRef.current

    if (mediaError === null) return

    console.dir(mediaError)
    if (mediaError?.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
      permissionAlert.openAlert()
    }

    return permissionAlert.resetAlert
  }, [mediaError])

  useEffect(() => {
    if (!permissionsGranted) return

    socketRef.current =
      socketRef.current || io(process.env.REACT_APP_SAAS_QUEUE_SOCKET)

    const socket = socketRef.current

    socket.on('connect_error', err => {
      errorLog(err)
      setError(new Error('Erro ao conectar-se à fila'))
    })

    connectQueue()

    return () => socketRef.current?.close()
  }, [connectQueue, permissionsGranted])

  return (
    <PageContainer>
      <Header />
      <Main>
        <BoxContent>
          <div className={cs.contentTop}>
            <Typography variant="h2" color="textSecondary" className={cs.title}>
              Já avisamos que você chegou!
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Dr(a) {professionalName} te atenderá em breve.
            </Typography>
          </div>
          <div className={cs.contentBottom}>
            <div className={cs.waitHere}>
              <HourGlassIcon className={cs.waitIcon} />
              <Typography variant="body1" color="primary">
                Aguarde aqui
              </Typography>
            </div>
            <div className={cs.previewBox}>
              <div className={cs.videoWrapper}>
                <LocalVideoPreview
                  identity={null}
                  fitMode="cover"
                  classes={{ container: cs.localPreviewContainer }}
                />
              </div>
            </div>
          </div>
        </BoxContent>
      </Main>
      <ErrorDialog error={error} dismissError={clearError} />
      <PermissionHandler ref={permissionRef} />
    </PageContainer>
  )
}

export default WaitingRoom
