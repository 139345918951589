import { Reducer } from 'redux'
import { AttendanceState } from './types'
import Handlers from './handlers'

const initialState: AttendanceState = {
  attendance: {
    id: null,
  },
  loading: false,
}

const reducer: Reducer<AttendanceState> = (
  state = initialState,
  { type, payload },
) => {
  const currHandler = Handlers[type]

  if (!currHandler) return state

  return currHandler(state, payload)
}

export default reducer
