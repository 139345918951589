import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  button: {
    background: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.error.medium,
    },
  },
}))

export default useStyles
