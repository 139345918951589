import { SubscriberStyle, PublisherStyle } from 'opentok-react/types/opentok'

export interface SubscriberDefaults {
  style: SubscriberStyle
  width: number | string
  height: number | string
  fitMode: 'cover' | 'contain'
}

export interface PublisherDefaults extends SubscriberDefaults {
  style: PublisherStyle
}

export const publisherDefaults: PublisherDefaults = {
  style: {
    archiveStatusDisplayMode: 'off',
    audioLevelDisplayMode: 'off',
    nameDisplayMode: 'off',
    buttonDisplayMode: 'off',
    videoDisabledDisplayMode: 'off',
  },
  width: '100%',
  height: '100%',
  fitMode: 'contain',
}

export const subscriberDefaults: SubscriberDefaults = {
  style: {
    audioLevelDisplayMode: 'off',
    nameDisplayMode: 'off',
    buttonDisplayMode: 'off',
    videoDisabledDisplayMode: 'off',
  },
  width: '100%',
  height: '100%',
  fitMode: 'contain',
}
