import React, { FunctionComponent } from 'react'
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import PermissionDialogTitle from './PermissionDialogTitle'
import PermissionIcons from './PermissionIcons'
import useStyles from './styles'

interface PermissionAlertDialogProps {
  open: boolean
  onClose(): void
}

const PermissionAlertDialog: FunctionComponent<PermissionAlertDialogProps> = ({
  open,
  onClose,
}) => {
  const cs = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm"
      disableBackdropClick
      style={{ zIndex: 20 }}
    >
      <PermissionDialogTitle />
      <DialogContent>
        <PermissionIcons />
        <DialogContentText style={{ marginTop: 20 }}>
          Para continuar a consulta, permita acesso à sua câmera e / ou
          microfone em seu navegador.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={cs.actions}>
        <Button onClick={onClose} variant="contained" color="primary">
          Ver instruções
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PermissionAlertDialog
