import React, { FunctionComponent } from 'react'

import Button from '@material-ui/core/Button'
// import Menu from './Menu/Menu'
import { ReactComponent as LogoConexa } from '@/assets/svg/logo_conexa.svg'
import useStyles from './styles'

import { Typography, Grid, Hidden } from '@material-ui/core'
import EndCallButton from '@/components/Buttons/EndCallButton'
import ToggleAudioButton from '@/components/Buttons/ToggleAudioButton'
import ToggleVideoButton from '@/components/Buttons/ToggleVideoButton'
import ToggleScreenShareButton from '@/components/Buttons/ToogleScreenShareButton'
// import FlipCameraButton from './FlipCameraButton'
import useVonageContext from '@/hooks/useVonageContext'

const MenuBar: FunctionComponent = () => {
  const cs = useStyles()
  const { isSharingScreen, toggleScreenShare } = useVonageContext()

  return (
    <>
      {isSharingScreen && (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={cs.screenShareBanner}
        >
          <Typography variant="h6">Você está apresentando sua tela</Typography>
          <Button onClick={() => toggleScreenShare()}>
            Parar apresentação
          </Button>
        </Grid>
      )}
      <footer className={cs.container}>
        <Grid container justify="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <LogoConexa />
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justify="center">
              <ToggleAudioButton /* disabled={isReconnecting} // TODO: resolver isRecnnecting */
              />
              <ToggleVideoButton /* disabled={isReconnecting} // TODO: resolver isRecnnecting */
              />
              <Hidden smDown>
                {!isSharingScreen && (
                  <ToggleScreenShareButton /* disabled={isReconnecting} // TODO: resolver isRecnnecting */
                  />
                )}
              </Hidden>
              {/* <FlipCameraButton /> */}
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justify="flex-end">
                {/* <Menu /> // TODO: Resolver se o menu é necessário */}
                <EndCallButton />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  )
}

export default MenuBar
