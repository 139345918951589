import {
  ConsultationRoomTypes,
  ConsultationRoomState,
  ConsultationRoom,
} from './types'

const Handlers = {
  [ConsultationRoomTypes.SET_ROOM]: (
    state: ConsultationRoomState,
    room: ConsultationRoom,
  ): ConsultationRoomState => ({
    ...state,
    room,
  }),
  [ConsultationRoomTypes.SET_LOADING]: (
    state: ConsultationRoomState,
    loading: boolean,
  ): ConsultationRoomState => ({
    ...state,
    loading,
  }),
}

export default Handlers
