import { combineReducers, Reducer } from 'redux'
import { ApplicationState } from '@/store'
import auth from './auth'
import consultationRoom from './consultationRoom'
import attendance from './attendance'
import clearPersist from '@/utils/clearPersist'

const rootReducer = combineReducers({
  auth,
  consultationRoom,
  attendance,
})

const clearStateInterceptor: Reducer<ApplicationState> = (state, action) => {
  if (action.type === 'CLEAR_STATE') {
    clearPersist()
    state = undefined
  }

  return rootReducer(state, action)
}

export default clearStateInterceptor
