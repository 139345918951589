import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  spinner: {
    maxWidth: 0,
    opacity: 0,
    marginRight: 0,
    transition: 'all .2s ease-in-out',
  },
  activeSpinner: {
    marginRight: 10,
    maxWidth: 100,
    opacity: 1,
  },
  disableClick: {
    pointerEvents: 'none',
  },
})

export default useStyles
