import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  actions: {
    justifyContent: 'flex-end',
    padding: 12,
  },
})

export default useStyles
