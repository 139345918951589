import { AuthTypes, AuthState, User } from './types'

const Handlers = {
  [AuthTypes.SET_USER]: (state: AuthState, user: User): AuthState => ({
    ...state,
    user,
  }),
}

export default Handlers
