import React, {
  useState,
  ForwardRefRenderFunction,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'

import PermissionAlertDialog from '@/components/PermissionAlertDialog'
import PermissionInstructionsDialog from '@/components/PermissionInstructionsDialog'
import { ZendeskAPI } from 'react-zendesk'

export interface PermissionHandles {
  openAlert: () => void
  resetAlert: () => void
}

const PermissionHandler: ForwardRefRenderFunction<PermissionHandles> = (
  props,
  ref,
) => {
  const [permissionOpen, setPermissionOpen] = useState(false)
  const [permissionInstructionOpen, setPermissionInstructionOpen] = useState(
    false,
  )

  const openAlert = useCallback(() => {
    setPermissionOpen(true)
  }, [])

  const resetAlert = useCallback(() => {
    setPermissionOpen(false)
    setPermissionInstructionOpen(false)
  }, [])

  useImperativeHandle(ref, () => ({
    openAlert,
    resetAlert,
  }))

  const showInstructions = useCallback(() => {
    setPermissionInstructionOpen(true)
    setPermissionOpen(false)
  }, [])

  return (
    <>
      <PermissionAlertDialog open={permissionOpen} onClose={showInstructions} />
      <PermissionInstructionsDialog
        open={permissionInstructionOpen}
        onClose={() => setPermissionInstructionOpen(false)}
        onHelp={() => {
          ZendeskAPI('webWidget', 'open')
          setPermissionInstructionOpen(false)
        }}
      />
    </>
  )
}

export default forwardRef(PermissionHandler)
