import React, { FunctionComponent, useMemo } from 'react'
import LocalVideoPreview from '@/components/LocalVideoPreview'
import { useParams } from 'react-router-dom'

import { Button, Grid, Hidden } from '@material-ui/core'
import useStyles from './styles'
import ToggleAudioButton from '@/components/Buttons/ToggleAudioButton'
import ToggleVideoButton from '@/components/Buttons/ToggleVideoButton'

import useVonageContext from '@/hooks/useVonageContext'
import decodeVonage, { IConnectionData } from '@/utils/decodeVonageToken'

interface OwnProps {
  mediaError?: Error
  permissionsGranted: boolean
}

const PreviewAndConnect: FunctionComponent<OwnProps> = ({
  mediaError,
  permissionsGranted,
}) => {
  const classes = useStyles()
  const { videoToken } = useParams<{ videoToken: string }>()
  const { connect } = useVonageContext()

  const blockConnect = !!mediaError || !permissionsGranted

  const identityName = useMemo(
    () => (decodeVonage(videoToken).connection_data as IConnectionData).name,
    [videoToken],
  )

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item md={7} sm={12} xs={12}>
        <div className={classes.localPreviewContainer}>
          <LocalVideoPreview identity={identityName} />
        </div>
        <div className={classes.mobileButtonBar}>
          <Hidden mdUp>
            <ToggleAudioButton
              className={classes.mobileButton}
              disabled={blockConnect}
            />
            <ToggleVideoButton
              className={classes.mobileButton}
              disabled={blockConnect}
            />
          </Hidden>
        </div>
      </Grid>
      <Grid item md={5} sm={12} xs={12}>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ height: '100%' }}
        >
          <div>
            <Hidden smDown>
              <ToggleAudioButton
                className={classes.deviceButton}
                disabled={blockConnect}
              />
              <ToggleVideoButton
                className={classes.deviceButton}
                disabled={blockConnect}
              />
            </Hidden>
          </div>
          <div className={classes.joinButtons}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => connect(videoToken)}
              disabled={blockConnect}
            >
              Entrar
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PreviewAndConnect
