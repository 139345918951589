import { createStore, Store } from 'redux'
import { persistStore, persistReducer, Persistor } from 'redux-persist'
import { reduxPersistConfig } from '@/config/redux-persist'

import rootReducer from './ducks'
import { AuthState } from './ducks/auth/types'
import { ConsultationRoomState } from './ducks/consultationRoom/types'
import { AttendanceState } from './ducks/attendance/types'

export interface ApplicationState {
  auth: AuthState
  consultationRoom: ConsultationRoomState
  attendance: AttendanceState
}

const persistedReducer = persistReducer(reduxPersistConfig, rootReducer)

export default (): {
  store: Store<ApplicationState>
  persistor: Persistor
} => {
  const store: Store<ApplicationState> = createStore(persistedReducer)
  const persistor: Persistor = persistStore(store)
  return { store, persistor }
}
