import Api from '@/services/Api'

class ConsultationGuide {
  private api = Api()

  public getByName(name: string) {
    const endpoint = `/rooms/${name}`

    return this.api.get(endpoint)
  }
}

export default new ConsultationGuide()
