import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  '@global': {
    // Zendesk balloon
    'iframe#launcher': {
      bottom: '50px!important',
    },
  },
  videoContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',

    '& >div': {
      flex: 1,
    },
  },
})

export default useStyles
