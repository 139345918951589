export enum AttendanceTypes {
  SET_ATTENDANCE = '@attendance/SET_ATTENDANCE',
  SET_LOADING = '@attendance/SET_LOADING',
}

export interface Attendance {
  id: number
}

export interface AttendanceState {
  readonly attendance: Attendance
  readonly loading: boolean
}
