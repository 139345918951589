declare let process: {
  env: {
    NODE_ENV: 'development' | 'production' | 'test' | 'homologation'
  }
}

export const isDev = (): boolean => {
  return Boolean(
    process?.env?.NODE_ENV === 'development' || localStorage.getItem('devMode'),
  )
}

export const isHomolog = (): boolean => {
  return Boolean(process?.env?.NODE_ENV === 'homologation')
}

export const isProd = (): boolean => {
  return Boolean(process?.env?.NODE_ENV === 'production')
}
