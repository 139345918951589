import React, { FunctionComponent } from 'react'
import { ReactComponent as Logo } from '@/assets/svg/logo_conexa.svg'
import useStyles, { Header as HeaderRoot } from './styles'

const Header: FunctionComponent<{ className?: string }> = ({
  children,
  ...props
}) => {
  const cs = useStyles()

  return (
    <HeaderRoot {...props}>
      <div className="maxContainer">
        <Logo className={cs.logo} />
        {children ? <div className={cs.rightSide}>{children}</div> : ''}
      </div>
    </HeaderRoot>
  )
}

export default Header
