import React, { PropsWithChildren } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles'
import Dots from '@/components/Dots'

const H1 = styled('main')(({ theme }) => ({
  fontFamily: 'Nunito, sans-serif',
  fontStyle: 'normal',
  fontWeight: 'bold',
  [theme.breakpoints.down('xs')]: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  fontSize: '24px',
  lineHeight: '30px',
  color: theme.palette.primary.main,
}))

const Image = styled('img')({
  width: 130,
  height: 130,
})

const ShadowBox = styled('div')({
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.12)',
  padding: 25,
  borderRadius: 8,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
})

const Text = styled('span')(({ theme }) => ({
  fontFamily: 'Nunito, sans-serif',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '20px',
  textAlign: 'center',
  color: '#575453',
}))

const useStyles = makeStyles({
  buttonNext: {
    alignSelf: 'flex-end',
  },

  container: {
    padding: 30,
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },

  box: {
    flex: 1,
    height: 'auto',
  },
})

interface CardProps {
  info: any
  counter_dots: number
  current_dot: number
}

export default function CardInstruction({
  info,
  counter_dots,
  current_dot,
}: PropsWithChildren<CardProps>) {
  const cs = useStyles()

  return (
    <Box width={'100%'} className={cs.container}>
      <ShadowBox classes={{ root: cs.box }}>
        <H1>{info.title}</H1>
        <Image src={info.image} alt="" />
        <Text>{info.description}</Text>
        <Dots count={counter_dots} current={current_dot} />
      </ShadowBox>
    </Box>
  )
}
