import React, { FunctionComponent } from 'react'
import {
  Button,
  CircularProgress,
  ButtonProps,
  CircularProgressProps,
} from '@material-ui/core'
import useStyles from './styles'

interface BusyButtonProps extends ButtonProps {
  busy?: boolean
  circularProgressProps?: CircularProgressProps
}

const BusyButton: FunctionComponent<BusyButtonProps> = ({
  children,
  busy = false,
  circularProgressProps,
  className = '',
  ...rest
}) => {
  const cs = useStyles()

  return (
    <Button
      color="primary"
      variant="contained"
      className={[busy ? cs.disableClick : '', className].join(' ')}
      {...rest}
    >
      <CircularProgress
        classes={{ root: cs.spinner }}
        className={busy ? cs.activeSpinner : ''}
        color="inherit"
        size={20}
        {...circularProgressProps}
      />
      {children}
    </Button>
  )
}

export default BusyButton
