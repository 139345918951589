import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'

const useStyles = makeStyles({
  iconColor: {
    color: '#EF4056',
  },
})

const BlockedIcon: React.FC = () => {
  const classes = useStyles()
  return (
    <div>
      <div>
        <CancelOutlinedIcon className={classes.iconColor}></CancelOutlinedIcon>
      </div>
    </div>
  )
}

export default BlockedIcon
