import React, { PropsWithChildren } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { styled } from '@material-ui/core/styles'

const useStyles = makeStyles({
  dotColorActive: {
    backgroundColor: '#8296E5',
  },
  dotColorInactive: {
    backgroundColor: '#DAD2D0',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const Dot = styled('div')({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  margin: '5px',
})

interface DotsProps {
  count: number
  current: number
}

export default function Dots({ count, current }: PropsWithChildren<DotsProps>) {
  const classes = useStyles()

  function renderDots(count: number) {
    const component = []
    for (let i = 1; i <= count; i++) {
      if (i === current) {
        component.push(<Dot key={i} className={classes.dotColorActive}></Dot>)
      } else {
        component.push(<Dot key={i} className={classes.dotColorInactive}></Dot>)
      }
    }

    return component
  }

  return <div className={classes.container}>{renderDots(count)}</div>
}
