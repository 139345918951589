import { isDev } from '@/utils/verifyEnv'
import { AxiosError } from 'axios'

export const log = (...args: unknown[]): void => {
  const reallyArgs = args.filter(arg => arg !== 'force')
  if (reallyArgs.length !== args.length || isDev()) console.log(...reallyArgs)
}

export const errorLog = <T>(err: T | AxiosError | Error): void => {
  const toLog = 'response' in err ? err.response : err
  return log('force', 'error>>', toLog)
}
