import React, { FunctionComponent } from 'react'

import MainParticipant from '@/components/MainParticipant'
import ParticipantList from '@/components/ParticipantList'
import { styled } from '@material-ui/core'

export const Container = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  width: '100%',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const Room: FunctionComponent = () => {
  return (
    <Container>
      <MainParticipant />
      <ParticipantList />
    </Container>
  )
}

export default Room
