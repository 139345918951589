import { Reducer } from 'redux'
import { AuthState } from './types'
import Handlers from './handlers'

const initialState: AuthState = {
  user: {
    name: '',
    patientId: null,
    userToken: null,
  },
  loading: false,
}

const reducer: Reducer<AuthState> = (
  state = initialState,
  { type, payload },
) => {
  const currHandler = Handlers[type]

  if (!currHandler) return state

  return currHandler(state, payload)
}

export default reducer
