import jwtDecode from 'jwt-decode'
import camelcaseKeys from 'camelcase-keys'
import Api from '@/services/Api'
import allTrim from '@/utils/allTrim'

class Attendance {
  private api = Api()
  public create({ roomId, patientName }) {
    const endpoint = '/attendances/entries'

    return this.api.post(endpoint, {
      room_id: roomId,
      patient_name: patientName,
      term_id: 1,
    })
  }

  public update({
    userToken,
    ...rest
  }: {
    userToken: string
    professional_entry?: string /* Wed Nov 11 2020 16:23:40 GMT+0000 */
    start_patient_reception?: string /* Wed Nov 11 2020 16:23:40 GMT+0000 */
    patient_entry?: string /* Wed Nov 11 2020 16:23:40 GMT+0000 */
    end_patient_reception?: string /* Wed Nov 11 2020 16:23:40 GMT+0000 */
  }) {
    const endpoint = `/attendances`
    return this.api({
      method: 'patch',
      url: endpoint,
      data: rest,
      headers: {
        'Content-Type': 'application/json',
        token: userToken,
      },
    })
  }

  public sendRating({
    attendanceToken,
    score,
    comment,
    subject,
  }: {
    attendanceToken: string
    score: number
    comment?: string
    subject?: string
  }) {
    const { attendanceId } = camelcaseKeys(jwtDecode(attendanceToken))
    const endpoint = `/attendances/${allTrim(attendanceId)}/rating`

    const bodyObj = {
      score,
      subject: subject || 'paciente',
    }

    comment && Object.assign(bodyObj, { comment })

    return this.api.post(endpoint, bodyObj, {
      headers: {
        token: attendanceToken,
      },
    })
  }
}

export default new Attendance()
