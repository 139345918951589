import Axios, { AxiosInstance } from 'axios'

const Api = (): AxiosInstance => {
  const config = {
    baseURL: process.env.REACT_APP_SAAS_API,
    headers: {
      common: {
        'Content-Type': 'application/json',
      },
    },
  }

  const instance = Axios.create(config)

  return instance
}

export default Api
