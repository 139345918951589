import { useEffect, useState } from 'react'
import { SessionStateType } from '@/types'
import useVonageContext from '../useVonageContext'

export default function useSessionState(): SessionStateType {
  const { session } = useVonageContext()
  const [state, setState] = useState<SessionStateType>(null)

  useEffect(() => {
    if (!session) return
    const setSessionState = () => setState(session.currentState || null)

    setSessionState()

    session
      .on('sessionConnected', setSessionState)
      .on('sessionDisconnected', setSessionState)
      .on('sessionReconnecting', setSessionState)
      .on('sessionReconnected', setSessionState)
    return () => {
      session
        .off('sessionConnected', setSessionState)
        .off('sessionDisconnected', setSessionState)
        .off('sessionReconnecting', setSessionState)
        .off('sessionReconnected', setSessionState)
    }
  }, [session])

  return state
}
