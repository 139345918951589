import { makeStyles, styled } from '@material-ui/core'

export const Content = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  [theme.breakpoints.down('xs')]: {
    borderTop: '2px solid',
    borderColor: theme.palette.gray.light,
  },
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  },
}))

export const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '16px 24px',
  width: '100%',
  maxWidth: 400,
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {},
  [theme.breakpoints.up('sm')]: {
    flex: 0,
    padding: 20,
    borderRadius: 8,
    border: '2px solid',
    borderColor: theme.palette.gray.light,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 600,
    flex: 'unset',
    minHeight: '60vh',
  },
}))

const useStyles = makeStyles(theme => ({
  '@global': {
    [theme.breakpoints.down('xs')]: {
      // Zendesk balloon
      'iframe#launcher': {
        bottom: '80px!important',
      },
    },
  },
  boxProfessionalWrapper: {
    width: '100%',
    display: 'flex',
    flexShrink: 0,
    flexBasis: 'auto',
    background: theme.palette.background.dark,

    '& .maxContainer': {
      display: 'flex',
      alignItems: 'center',
      padding: 16,
    },
  },

  iconStetos: {
    marginRight: 8,
    flexShrink: 0,
    flexBasis: 'auto',
  },

  colorDark: { color: theme.palette.primary.dark },

  textOverflow: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
  },

  wellcome: {
    marginBottom: 8,
  },

  contentTop: {
    marginBottom: 32,
  },

  contentBottom: {
    marginTop: 32,
  },

  termCheck: {
    paddingLeft: 0,
  },

  termBox: {
    display: 'flex',
    alignItems: 'center',
  },

  link: {
    color: theme.palette.primary.main,
  },

  submitButton: {
    marginTop: 6,
  },
}))

export default useStyles
