import React, { FunctionComponent, useMemo } from 'react'
import { useHistory, useParams, Redirect } from 'react-router-dom'

import MenuBar from '@/components/MenuBar/'
import Room from '@/components/Room'
import DisconnectedHall from './DisconnectedHall'
import MobileTopMenuBar from '@/components/MobileTopMenuBar'
import { PageContainer, Main } from '@/theme/styles'
import useStyles from './styles'

import isUnsuportedClient from '@/utils/isUnsuportedClient'
import useSessionState from '@/hooks/useSessionState'
import { useSelector } from 'react-redux'
import { UserToken } from '@/store/ducks/auth/getters'

const VideoRoom: FunctionComponent = () => {
  useStyles()
  const userToken = useSelector(UserToken)
  const history = useHistory()
  const { userType, attendanceId } = useParams<{
    userType?: string
    attendanceId: string
  }>()
  const sessionState = useSessionState()

  if (isUnsuportedClient()) {
    history.replace('/unsupported-client')
  }

  const isDoctor = useMemo(() => userType === `doctor`, [userType])

  const redirectToOnDisconnect = useMemo(
    () =>
      isDoctor
        ? `/professional-app${attendanceId ? `?rating=${attendanceId}` : ''}`
        : `/thankyou/${userToken} `,
    [userToken, attendanceId, isDoctor],
  )

  return (
    <PageContainer>
      {/* {!room.participants?.size && participants.length === 0 && !isDoctor && (
        <APPDialog /> // TODO: Arrumar um substituto pra esse cara
      )} */}
      <Main>
        {!sessionState ? (
          <DisconnectedHall />
        ) : sessionState === 'disconnected' ? (
          <Redirect to={redirectToOnDisconnect} />
        ) : (
          // )
          <>
            {/* <ReconnectingNotification /> */}
            <MobileTopMenuBar />
            <Room />
            <MenuBar />
          </>
        )}
      </Main>
    </PageContainer>
  )
}

export default VideoRoom
