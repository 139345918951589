import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react'

import Router from '@/router'
import ErrorDialog from '@/components/ErrorDialog/ErrorDialog'
// import { VideoProvider as TwilioProvider } from '@/components/VideoProvider'
import Zendesk from 'react-zendesk'

import VonageProvider from './components/VonageProvider'

import { useGlobalStyles } from '@/theme/styles'
import useHeight from '@/hooks/useHeight/useHeight'
import { useAppState } from '@/state'
import useGTM from '@elgorditosalsero/react-gtm-hook'
// import useVideoErrorMiddleware from '@/hooks/useVideoErrorMiddleware'

import { makeStyles } from '@material-ui/core'
// import useConnectionOptions from '@/config/useConnectionOptions'
import useZendeskSettings from '@/config/zendesk'

const useStyles = makeStyles({
  mainContainer: ({ height }: { height?: string | number }) => ({
    minHeight: height,
    display: 'flex',
    flexDirection: 'column',
  }),
})

const { REACT_APP_GTM_ID: GTM_ID } = process.env

const App: FunctionComponent = (/* props */) => {
  const { error, setError } = useAppState()
  // const connectionOptions = useConnectionOptions()
  const init = useRef(useGTM().init)
  const zendeskSettings = useZendeskSettings()
  // const onError = useVideoErrorMiddleware(setError)

  const height = useHeight()
  // Prevents the 100vh bug on some devices

  useGlobalStyles()

  const { mainContainer } = useStyles({ height })

  const clearError = useCallback(() => setError(null), [setError])

  useEffect(() => init.current({ id: GTM_ID }), [])

  return (
    // <TwilioProvider options={connectionOptions} onError={onError} {...props}>
    <VonageProvider>
      <ErrorDialog dismissError={clearError} error={error} />
      <div className={mainContainer}>
        <Router />
        <Zendesk {...zendeskSettings} />
      </div>
    </VonageProvider>
    // </TwilioProvider>
  )
}

export default App
