import { AttendanceTypes, AttendanceState, Attendance } from './types'

const Handlers = {
  [AttendanceTypes.SET_ATTENDANCE]: (
    state: AttendanceState,
    attendance: Attendance,
  ): AttendanceState => ({
    ...state,
    attendance,
  }),
  [AttendanceTypes.SET_LOADING]: (
    state: AttendanceState,
    loading: boolean,
  ): AttendanceState => ({
    ...state,
    loading,
  }),
}

export default Handlers
