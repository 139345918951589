import React from 'react'
import Header from '@/components/Header'
import { PageContainer, Main } from '@/theme/styles'
import { Typography } from '@material-ui/core'
import useStyles from './styles'

const OfficeNotFound: React.FC = () => {
  const { message } = useStyles()

  return (
    <PageContainer>
      <Header />
      <Main>
        <Typography variant="subtitle1" color="textPrimary" className={message}>
          Este consultório não existe. Consulte a clínica para receber o link
          correto {':)'}
        </Typography>
      </Main>
    </PageContainer>
  )
}

export default OfficeNotFound
