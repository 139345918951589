import { useSelector } from 'react-redux'
import { ApplicationState } from '@/store'
import { useTheme } from '@material-ui/core'
import { useEffect, useMemo } from 'react'
import { ZendeskAPI, IZendeskProps } from 'react-zendesk'

const { REACT_APP_ZENDESK_KEY: ZENDESK_KEY } = process.env

const useZendesk = (): IZendeskProps => {
  const { main } = useTheme()?.palette.primary || {}
  const name = useSelector((state: ApplicationState) => state.auth.user.name)

  // https://developer.zendesk.com/embeddables/docs/widget/introduction
  const settings = useMemo(
    () => ({
      zendeskKey: ZENDESK_KEY,
      color: { theme: main },
      webWidget: {
        launcher: {
          chatLabel: {
            '*': 'Ajuda',
          },
        },
      },
    }),
    [main],
  )

  useEffect(() => {
    ZendeskAPI('webWidget', 'show')
    ZendeskAPI('webWidget', 'chat:addTags', ['saas', 'conexa_clinic'])
  }, [])

  useEffect(() => {
    if (name) {
      ZendeskAPI('webWidget', 'prefill', {
        name: {
          value: name,
        },
      })
    }
  }, [name])

  return settings
}

export default useZendesk
