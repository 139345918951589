import React, { FunctionComponent, useMemo } from 'react'

import Participant from '@/components/Participant'

import useVonageContext from '@/hooks/useVonageContext'
import useRemoteScreenSharingStream from '@/hooks/useRemoteScreenSharingStream'
import useRemoteCameraStreams from '@/hooks/useRemoteCameraStreams'

import clsx from 'clsx'
import useStyles from './styles'

const ParticipantList: FunctionComponent = () => {
  const screenSharingStream = useRemoteScreenSharingStream()
  const cameraStreams = useRemoteCameraStreams()
  const { localMainStream, isSharingScreen } = useVonageContext()
  const classes = useStyles()
  const isSomeoneSharingScreen = useMemo(
    () => isSharingScreen || screenSharingStream,
    [isSharingScreen, screenSharingStream],
  )
  const showLocalStream = useMemo(
    () => localMainStream && (cameraStreams.length || isSharingScreen),
    [localMainStream, cameraStreams, isSharingScreen],
  )
  // const mainParticipant = useMainParticipant() // TEMP: para referencia de como tratar mainParticipant no futuro

  if (!cameraStreams.length && !localMainStream) return null

  return (
    <aside
      className={clsx(classes.container, {
        [classes.isRemoteParticipantScreenSharing]: screenSharingStream,
      })}
    >
      <div className={classes.scrollContainer}>
        {showLocalStream ? (
          <Participant stream={localMainStream} isLocalParticipant />
        ) : null}
        {cameraStreams.map((stream, index) => {
          // const isSelected = participant === selectedParticipant
          // const hideParticipant = // TEMP: para referencia de como tratar mainParticipant no futuro
          //   participant === mainParticipant &&
          //   participant !== screenShareParticipant &&
          //   !isSelected

          if (index === 0 && !isSomeoneSharingScreen) return null

          return <Participant stream={stream} key={stream.streamId} />
        })}
      </div>
    </aside>
  )
}

export default ParticipantList
