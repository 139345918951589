import React, { FunctionComponent } from 'react'
import EndCallButton from '@/components/Buttons/EndCallButton'
// import Menu from '@/components/MenuBar/Menu/Menu'
import Header from '@/components/Header'
import useStyles from './styles'

const MobileTopMenuBar: FunctionComponent = () => {
  const cs = useStyles()

  return (
    <Header className={cs.container}>
      <EndCallButton className={cs.endCallButton} />
      {/* <Menu buttonClassName={cs.settingsButton} /> // TODO: Resolver se o menu é necessário */}
    </Header>
  )
}

export default MobileTopMenuBar
