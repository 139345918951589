import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    bottom: 0,
    left: 0,
    right: 0,
    height: `${theme.footerHeight}px`,
    width: '100%',
    display: 'flex',
    padding: '0 1.43em',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: `${theme.mobileFooterHeight}px`,
      maxHeight: 100,
      padding: 0,
    },
  },
  screenShareBanner: {
    position: 'fixed',
    zIndex: 10,
    bottom: `${theme.footerHeight}px`,
    left: 0,
    right: 0,
    height: '104px',
    background: 'rgba(0, 0, 0, 0.5)',
    '& h6': {
      color: 'white',
    },
    '& button': {
      background: 'white',
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      margin: '0 2em',
      '&:hover': {
        color: '#600101',
        border: `2px solid #600101`,
        background: '#FFE9E7',
      },
    },
  },
  hideMobile: {
    display: 'initial',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

export default useStyles
