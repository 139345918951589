export enum AuthTypes {
  SET_USER = '@auth/SET_USER',
}

export interface User {
  name: string
  patientId?: number
  userToken?: string
}

export interface AuthState {
  readonly user: User
  readonly loading: boolean
}
