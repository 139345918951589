import React, { FunctionComponent, ReactText } from 'react'
import clsx from 'clsx'

import ParticipantInfo from '@/components/ParticipantInfo'
import { OTSubscriber } from 'opentok-react'

import { Stream } from 'opentok-react/types/opentok'

import useVonageContext from '@/hooks/useVonageContext'
import useStyles from './styles'

export interface ParticipantProps {
  key?: ReactText
  stream: Stream
  isLocalParticipant?: boolean
}

const Participant: FunctionComponent<ParticipantProps> = ({
  stream,
  isLocalParticipant = false,
}) => {
  const cs = useStyles()
  const { session } = useVonageContext()

  return (
    <ParticipantInfo stream={stream} isLocalParticipant={isLocalParticipant}>
      <div
        className={clsx(cs.container, { [cs.invertVideo]: isLocalParticipant })}
      >
        <OTSubscriber
          key={stream.streamId}
          properties={{
            width: '100%',
            height: '100%',
            fitMode: 'contain',
            subscribeToAudio: !isLocalParticipant,
            style: {
              buttonDisplayMode: 'off',
              nameDisplayMode: 'off',
              videoDisabledDisplayMode: 'off',
              audioLevelDisplayMode: 'off',
            },
          }}
          stream={stream}
          session={session}
        />
      </div>
    </ParticipantInfo>
  )
}

export default Participant
