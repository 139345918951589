export enum ConsultationRoomTypes {
  SET_ROOM = '@consultationRoom/SET_ROOM',
  SET_LOADING = '@consultationRoom/SET_LOADING',
}

export interface ConsultationRoom {
  id: number
  professionalFullname: string
  roomName: string
}

export interface ConsultationRoomState {
  readonly room: ConsultationRoom
  readonly loading: boolean
}
