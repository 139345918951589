import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: 40,
    maxWidth: 600,
    margin: '0 auto',
  },

  title: {
    marginBottom: 20,

    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },

  successMessage: {
    textAlign: 'center',
  },

  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  starsBox: {
    marginBottom: 32,
  },

  starBtn: {
    border: 'none',
    outline: 'none',
    background: 'none',
    padding: '4px 8px',
  },

  starIcon: {
    height: 'auto',
    width: 32,
    [theme.breakpoints.up('sm')]: {
      width: 40,
    },
  },

  actionsContainer: {
    width: '100%',
    display: 'flex',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },

  submitBtn: {
    marginTop: 32,
    opacity: 0,
    visibility: 'hidden',
    pointerEvents: 'none',
  },

  activeBtn: {
    opacity: 1,
    visibility: 'visible',
    pointerEvents: 'auto',
  },
}))

export default useStyles
