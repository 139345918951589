import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  '@global': {
    // Zendesk balloon
    'iframe#launcher': {
      bottom: '50px!important',
    },
  },
})

export default useStyles
