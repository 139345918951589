import { useMemo } from 'react'
import useVonageContext from '../useVonageContext'
import { Stream } from 'opentok-react/types/opentok'

const useRemoteCameraStreams = (): Stream[] => {
  const { streams } = useVonageContext()

  const remoteScreenSharingStream = useMemo(
    () => streams.filter(stream => stream.videoType === 'camera') || null,
    [streams],
  )

  return remoteScreenSharingStream
}

export default useRemoteCameraStreams
