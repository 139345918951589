import React, { FunctionComponent, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import useVonageContext from '@/hooks/useVonageContext'

import { PageContainer, GrowContainer } from '@/theme/styles'
import Room from '@/components/Room'
import MobileTopMenuBar from '@/components/MobileTopMenuBar'
import MenuBar from '@/components/MenuBar'
import { Fade } from '@material-ui/core'
import useStyles from './styles'

const TestPage: FunctionComponent = () => {
  useStyles()

  const { connect, session } = useVonageContext()
  const { VonageToken } = useParams<{
    VonageToken?: string
  }>()

  useEffect(() => {
    if (VonageToken) {
      return connect(VonageToken)
    }
  }, [VonageToken, connect])

  return (
    <PageContainer>
      {session && (
        <Fade in timeout={600}>
          <GrowContainer>
            <MobileTopMenuBar />
            <Room />
            <MenuBar />
          </GrowContainer>
        </Fade>
      )}
    </PageContainer>
  )
}

export default TestPage
