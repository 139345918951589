import React from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'

// import { Container } from './styles';
const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const LoadingContainer: React.FC = () => {
  const { root } = useStyle()

  return (
    <div className={root}>
      <CircularProgress color="secondary" size={50} />
    </div>
  )
}

export default LoadingContainer
