import React, { FunctionComponent } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import PermissionVideoBlockedIcon from './PermissionVideoBlockedIcon'
import PermissionAudioBlockedIcon from './PermissionAudioBlockedIcon'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const PermissionIcons: FunctionComponent = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <PermissionVideoBlockedIcon />
      <PermissionAudioBlockedIcon />
    </div>
  )
}

export default PermissionIcons
