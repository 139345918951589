import {
  isAndroid,
  isChrome,
  isIOS,
  isSafari,
  isFirefox,
  isBrowser,
  browserVersion,
  isEdge,
  browserName,
} from 'react-device-detect'

const isUnsuportedClient = (): boolean =>
  (isAndroid && !isChrome && browserName !== 'Samsung Browser') ||
  (isIOS && !isSafari) ||
  (isBrowser && !isFirefox && !isChrome && !isSafari && !isEdge) ||
  (isBrowser && isChrome && parseInt(browserVersion) < 75) ||
  (isBrowser && isSafari && parseInt(browserVersion) < 13) ||
  (isBrowser && isFirefox && parseInt(browserVersion) < 72)

export default isUnsuportedClient
