import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,

    '& video': {
      position: 'absolute',
      objectFit: 'contain!important', // TEMP: desenvolver uma verificação para cover/contain baseado nas dimensoes do video e da tela
    },
  },
  identity: {
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '0.1em 0.3em',
    fontSize: '1.2em',
    display: 'inline-flex',
    '& svg': {
      marginLeft: '0.3em',
    },
  },
  infoContainer: {
    position: 'absolute',
    zIndex: 2,
    height: '100%',
    width: '100%',
  },
  reconnectingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(40, 42, 43, 0.75)',
    zIndex: 1,
  },
  fullWidth: {
    // gridArea: '1 / 1 / 2 / 3',
    // [theme.breakpoints.down('sm')]: {
    //   gridArea: '1 / 1 / 3 / 3',
    // },
    // TODO: checar estilo e confirmar substituição por flex
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'black',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    '& svg': {
      transform: 'scale(2)',
    },
  },
})

export default useStyles
