import { makeStyles, styled } from '@material-ui/core'

export const BoxContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 24,
  width: '100%',
  maxWidth: 400,
  [theme.breakpoints.up('sm')]: {
    flex: 0,
    borderRadius: 8,
    border: '2px solid',
    borderColor: theme.palette.gray.light,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 600,
    flex: 'unset',
    minHeight: '60vh',
  },
}))

const useStyles = makeStyles(theme => ({
  colorDark: { color: theme.palette.primary.dark },

  title: {
    marginBottom: 16,
  },

  contentTop: {},

  contentBottom: {
    paddingTop: 24,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  waitHere: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },

  waitIcon: {
    marginRight: 8,
  },

  previewBox: {
    position: 'relative',
    width: '50%',
    margin: '0 auto',
    background: '#3d3d3d',
    borderRadius: 8,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  spinnerVideo: {
    margin: 20,
  },

  videoWrapper: {
    width: '100%',
  },

  localPreviewContainer: {
    paddingTop: '100%!important',
  },

  video: {
    width: '100%',
    left: 0,
    top: 0,
    height: '100%',
    objectFit: 'contain',
    position: 'absolute',
  },
}))

export default useStyles
