import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  '@global': {
    // Zendesk balloon
    'iframe#launcher': {
      [theme.breakpoints.down('xs')]: {
        bottom: '50px!important',
      },
    },
  },
  title: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
    },
    fontSize: '48px',
    lineHeight: '38px',
    textAlign: 'center',
    color: theme.palette.primary.dark,
    marginTop: '15px',
  },

  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '640px',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '0 60px',
    },
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingBottom: '15px',
    paddingTop: '15px',
    width: '100%',
  },
}))

export default useStyles
