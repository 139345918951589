import React, { FunctionComponent } from 'react'
import clsx from 'clsx'

import useStyles from './styles'

import Typography from '@material-ui/core/Typography'

import useIdentityFromStream from '@/hooks/useIdentityFromStream'
import { Stream } from 'opentok-react/types/opentok'
import useRemoteScreenSharingStream from '@/hooks/useRemoteScreenSharingStream'
import useVonageContext from '@/hooks/useVonageContext'

interface MainParticipantInfoProps {
  stream: Stream
  isLocalParticipant?: boolean
}

const MainParticipantInfo: FunctionComponent<MainParticipantInfoProps> = ({
  stream,
  children,
  isLocalParticipant,
}) => {
  const cs = useStyles()
  const { isSharingScreen } = useVonageContext()

  const participantIdentity = useIdentityFromStream(stream)

  const screenShareParticipant = useRemoteScreenSharingStream()

  return (
    <div
      className={clsx(cs.container, {
        [cs.fullWidth]: !screenShareParticipant,
      })}
    >
      <div className={cs.infoContainer}>
        {!stream ? null : (
          <div className={cs.identity}>
            {
              // TODO: Verificar necessidade desta feature
              /* <AudioLevelIndicator audioTrack={audioTrack} /> */
            }
            <Typography variant="body1" color="inherit">
              {participantIdentity}
              {isLocalParticipant && ' (Você)'}
              {(screenShareParticipant || isSharingScreen) && ' - Apresentando'}
            </Typography>
          </div>
        )}
      </div>
      {
        // TODO: Verificar necessidade desta feature
        /* {isParticipantReconnecting && (
        <div className={cs.reconnectingContainer}>
          <Typography variant="body1" style={{ color: 'white' }}>
            Reconnecting...
          </Typography>
        </div>
      )} */
      }
      {children}
    </div>
  )
}

export default MainParticipantInfo
